<template>
    <v-main app>

        <v-container
            class="horizontal-container  rounded-lg relative"
            :class="{'grey lighten-5': !$vuetify.theme.dark}"
            style="margin-top: 130px !important;"
        >
            <top-nav-menu class="mb-4" />

            <v-expand-transition mode="out-in">
                <router-view />
            </v-expand-transition>
        </v-container>

        <div class="flex-grow-1"></div>
    </v-main>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
      TopNavbar: () => import('./TopNavbar'),
      TopNavMenu: () => import('./TopNavMenu')
    },
    computed: {
        ...mapGetters(['getThemeMode'])
    },
    data() {
        return {}
    },

    methods: {}
}
</script>

<style lang="scss">
.v-main__wrap {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.horizontal-container {
    z-index: 1;
}
</style>
